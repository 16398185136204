img.card-img-top {
    height: 200px;
    object-fit: cover;
    object-position: top;
}

.nav-scroller .nav-item .active {
    background: #ffc107;
}


@media (max-width:767px) {
    .header-search .input-group {
        display: none;
    }
    .header-search.active .input-group {
        display: flex;
        position: absolute;
        left: 0;
        width: -webkit-calc(100% - 42px);
        width: calc(100% - 42px);
        background: #212529;
        z-index: 1;
        padding: 0 15px;
    }
    .header-search .mobile-search-btn {
        background: #ffc107;
        color:#212529;
    }
    .header-search.active .mobile-search-btn {
        position: absolute;
        right: 6px;
        z-index: 1;
    }
}